.flow-chart-wrapper {
    margin-top: -10px;
    width: 100%;
}

.flow-chart-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
}

.chart-container {
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
}

/* Styles for node labels */
.chart-container .label {
    font-size: 12px;
    line-height: 1.5;
    font-family: Verdana !important;
}

.chart-container svg {
    max-width: 100%;
    height: auto;
    min-width: 600px;
}
#mermaid-chart {
    max-width: 700px !important;
}

.chart-container svg foreignObject {
    padding-top: 4px !important;
    overflow: visible !important;
}

@media (max-width: 768px) {
    .flow-chart-title {
        font-size: 16px;
    }

    .chart-container {
        padding: 0;
    }

    .chart-container svg {
        min-width: unset;
    }

}