.inspection-list {
    overflow: auto;

    th.table-header-cell {
        padding: 0;
        width: 24px;
        padding-bottom: 15px; /* Add padding to the bottom of the th elements */
        max-width: 24px; /* Max width for the header cells */
    }

    td.MuiTableCell-root {
        padding-top: 0;
        padding-bottom: 0;
    }

    .rotated-header {
        transform: rotate(90deg);
        white-space: nowrap;
        margin-top: -45px;
        max-width: 20px;
    }

    .no-border {
        border-bottom: none; /* Remove row lines */
    }

    .small-padding {
        padding: 4px; /* Adjust padding to reduce row height */
    }

    .first-column {
        min-width: 160px;
    }

}
