html {
  font-size: 14px;
}
body {
  margin: 0;

  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

}
.MuiDrawer-paper {
  .cls-1 {
    fill: rgba(0, 0, 0, 0.54);
    margin-left:-12px;
  }
}
.markdown-container {
  max-width: 1000px;
  padding: 15px;
  padding-top: 0;
}

div.MuiAccordion-root.Mui-expanded {
  margin: 0 8px;
}